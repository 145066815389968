import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material';
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../dialogs/ConfirmDialog'
import styles from './PublicationImageCard.module.scss'
import SkeletonImage from '../skeleton/image'

interface IProps {
  image: any,
  isLoading?: boolean,
  imageId: number | string
  canBeDeleted: boolean
  handleOpenDetails?: any,
  height?: number;
  width?: number;
  onDeleteImage?: any;
  name?: string;
}

export const PublicationImageCard = (props: IProps) => {
  const { image, imageId, canBeDeleted, handleOpenDetails, isLoading, height,onDeleteImage, name, width } = props

  const { t } = useTranslation()

  const [openDeletePopup, setOpenDeletePopup] = useState(false)

  const handleConfirm = () => {
    if(onDeleteImage) {
      onDeleteImage(imageId)
    }
    setOpenDeletePopup(false)
  }

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }

  return (
    <>
      {openDeletePopup && (
        <ConfirmDialog
          open={openDeletePopup}
          handleOpen={() => {
            handleOpen()
          }}
          handleConfirm={() => {
            handleConfirm()
          }}
          dialogTitle={t('Delete Image')}
          dialogMessage={t('Are you sure to delete imageId', {
            imageId,
          })}
        />
      )}
      <div className={styles.container}>
        <Box position='relative' display='inline-flex'>
          {isLoading ?  <SkeletonImage /> :  <img
            src={image}
            alt=''
            className={styles.image}
            onClick={() => handleOpenDetails && handleOpenDetails(imageId)}
          />}

          {canBeDeleted && (
            <IconButton
              className={styles.closeButton}
              onClick={handleOpen}
              size='small'
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
                backgroundColor: '#c11b1e',
                opacity: 0.8,
                color: 'white',
              }}
            >
              <CloseIcon sx={{ width: 15, height: 15 }} />
            </IconButton>
          )}
        </Box>

        <div>
          {!isLoading && <p className={styles.imageText}>
            {name ? `${name}` : t('Image ID') + ': ' + imageId}
          </p>}
        </div>
      </div>
    </>
  )
}

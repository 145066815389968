import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next';


import styles from './ReleaseForm.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { typeImageForDownload } from '../../../../asset-details/AssetDetails'
import { BASE_URL } from '../../../../../../../utils/constants'
import { release } from 'os';

interface IProps {
  releases: IReleaseForm[];
}

interface IReleaseForm {
  logo: any
  releaseFormType: string
  pdfReleaseFormType: string
  digitalReleaseFormType: string
  pathPdf?: string,
  pathDigital?: string,
  name?: string,
}

export const ReleaseForm = (props: IProps) => {
  const { t } = useTranslation()

  const handleDownload = async (release: IReleaseForm) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    const urlData = `${window.location.origin}/${release.pathPdf}`
    const res = await instance.get(`${urlData}`, { responseType: 'arraybuffer' }) as any
    let blob = new Blob([res], { type: `application/pdf` }),
      url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${release.name}.pdf`)
    document.body.appendChild(link)
    link.click()
    Notiflix.Loading.remove()
  }
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {/* <img src={logo} alt='logo' className={styles.logo} /> */}

        <span className={styles.title}>{t('Releases Forms')}</span>
      </div>
      <div className={styles.bottom}>
        {props.releases.map((itm: IReleaseForm) => (
          <div key={`release-form-download-${itm.releaseFormType}`} className={styles.textAndButtonContainer}>
            <div className={styles.italicText}>{itm.pdfReleaseFormType}</div>
            <Button onClick={() => handleDownload(itm)} className={styles.downloadButton}>{t('Download')}</Button>
          </div>
        ))}
      </div>
    </div>
  )
}

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import {
  Button,
  Container,
  Grid,
  IconButton,
  ImageListItem
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Color, { Palette } from 'color-thief-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  constants, BUYER, ROUTES
} from '../../../../utils/constants'
import { SearchBar } from '../../../shared-components/search/SearchBar'
import styles from './AssetDetails.module.scss'
import instance  from '../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/rootReducer'
import SkeletonAssetsDetail from '../../../shared-components/skeleton/assetsDetail'
import Skeleton from '@mui/material/Skeleton'
import {  IImage } from '../../../../utils/types'
import { CardCreditSubscription } from '../../../shared-components/cardsBuy/CardCreditSubscription'
import { daysBetweenDates } from '../../../../utils/helpers'
import moment from 'moment'

export const typeImageForDownload = (typeImage?: string) => {
  switch (typeImage) {
    case 'eps':
      return 'application/postscript'
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    default:
      return 'image/jpeg'
  }
}

export const AssetDetailsBuy = () => {
  const { t } = useTranslation()
  const { assetName } = useParams()
  const splitData = assetName?.split('-') || []
  const assetId = splitData[splitData?.length - 1];
  const navigate = useNavigate()
  const [openAddToLightbox, setOpenAddToLightbox] = useState(false)
  const [imageData, setImageData] = useState<IImage>({})
  const [isLoading, setIsLoading] = useState(false)
  const [planInformation, setPlanInformation] = useState<any>({})
  const [imageInformation1, setImageInformation1] = useState<any>({})
  const userRole = useSelector((state: RootState) => state.user.userRole)
  const showOptionsUser = userRole?.slug === BUYER

  const { search } = useLocation()
  const dataParams = new URLSearchParams(search)

  const account = useSelector((state: RootState) => state.user.usersData) as any;

  const [openZoomInModal, setOpenZoomInModal] = useState(false)

  const getImageInformation = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`images/${assetId}`)
      setImageData(res?.data || [])
      setIsLoading(false)
    } catch (error) {
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getImageInformation()
  }, [assetId])

  useEffect(() => {
    const plan = dataParams.get('plan');
    if(imageData?.id && account?.id) {
      const plan = dataParams.get('plan');
      const category = dataParams.get('category');
      const size = dataParams.get('size');
      const findItem =imageData?.sizes?.find((sizeItem: any) => sizeItem?.id?.toString() === size) as any;
      setImageInformation1({
        downloadDetails: `${category?.toUpperCase()} - ${findItem?.name} - ${Number(findItem?.pixels?.width)?.toFixed(0)} x ${Number(findItem?.pixels?.height)?.toFixed(0)} px`,
        license: findItem?.licenseName,
        category: category,
        size: size,
        title: imageData?.title
      })
      if(plan === 'subscription' && account?.id) {
        const dataPlan = {
          plan: 'Subscription',
          price: `1 ${t('Download')}`,
          download_left:  account.activeSubscription ? `${Number(account?.activeSubscription?.downloads_used)}/${Number(account?.activeSubscription?.downloads)} ${t('Download')}` : '-',
          expire: account.activeSubscription ? `${daysBetweenDates( moment(account?.activeSubscription?.expiresAt), moment())} ${t('Days')}` : '-',
          disabled: Number(account?.activeSubscription?.downloads) - Number(account?.activeSubscription?.downloads_used) === 0 || !account.activeSubscription,
        }
        setPlanInformation({...dataPlan})
      }
      if(plan === 'credits' && account?.id) {
        const dataPlan = {
          plan: t('Credits'),
          priceCredits: findItem?.price,
          price: `${findItem?.price} ${t('Credits')}`,
          download_left: `${Number(account?.creditsAvailable)} ${t('Credits')}`,
          expire: account?.activeCredits?.expiresAt || '-',
          disabled: Number(account?.creditsAvailable) < findItem?.price,
        }
        setPlanInformation({...dataPlan})
      }
      if(plan === 'fast-download' && account?.id) {
        const dataPlan = {
          plan: t('fast-download'),
          price: '15 Euro',
          download_left: ``,
          expire: ''
        }
        setPlanInformation({...dataPlan})
      }
    }
    if(plan === 'fast-download') {
      const category = dataParams.get('category');
      const size = dataParams.get('size');
      const findItem =imageData?.sizes?.find((sizeItem: any) => sizeItem?.id?.toString() === size) as any;
      const dataPlan = {
        plan: t('fast-download'),
        price: '15 Euro',
        download_left: ``,
        expire: ''
      }
      setImageInformation1({
        downloadDetails: `${category?.toUpperCase()} - ${findItem?.name} - ${parseInt(findItem?.pixels?.width, 10)} x ${parseInt(findItem?.pixels?.height, 10)} px`,
        license: findItem?.licenseName,
        category: category,
        size: size,
        title: imageData?.title
      })
      setPlanInformation({...dataPlan})
    }
  }, [account?.id, imageData?.id])

  const handleOpen = () => {
    setOpenZoomInModal(!openZoomInModal)
  }

  const handleOpenAddToLightbox = () => {
    setOpenAddToLightbox(!openAddToLightbox)
  }
  const handlePayCredits = async () => {
    const category = dataParams.get('category');
    const size = dataParams.get('size');
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      try {
        const dataPayload = {
          image_id: Number(assetId),
          image_size_id: Number(size),
          method: 'credits',
          category: category
        }
        const response = await instance.post(`orders`, { ...dataPayload }) as any
        if (response?.data?.link && category) {
          const res = await instance.get(`${response?.data?.link}`, { responseType: 'arraybuffer' }) as any
          let blob = new Blob([res], { type: `image/${typeImageForDownload(category)}` }),
            url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${imageData?.title}.${category}`)
          document.body.appendChild(link)
          setPlanInformation({...planInformation, download_left: `${Number(account?.creditsAvailable - Number(planInformation.priceCredits))} Credits`})
          link.click()
          Notiflix.Loading.remove()
        } else {
          Notiflix.Loading.remove()
          Notiflix.Report.failure(
            'Error',
            `${response?.message}`,
            'OK'
          )
        }
      } catch (e) {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          'Failed to fetch data. Please try again later.',
          'OK'
        )
      }
  }
  const handlePaySubscriptions = async () => {
    const category = dataParams.get('category');
    const size = dataParams.get('size');
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const dataPayload = {
        image_id: Number(assetId),
        image_size_id: Number(size),
        method: 'subscription',
        category: category
      }
      const response = await instance.post(`orders`, { ...dataPayload }) as any
      if (response?.data?.link && category) {
        Notiflix.Loading.remove()
        const res = await instance.get(`${response?.data?.link}`, { responseType: 'arraybuffer' }) as any
        let blob = new Blob([res], { type: `image/${typeImageForDownload(category)}` }),
          url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${imageData?.title}.${category}`)
        document.body.appendChild(link)
        link.click()
        setPlanInformation({...planInformation, download_left: `${Number(account?.activeSubscription?.downloads_used + 1)}/${Number(account?.activeSubscription?.downloads)} Downloads`})
        Notiflix.Loading.remove()
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${response?.message}`,
          'OK'
        )
      }
    } catch (e) {
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  const downloadImage = async () => {
    const plan = dataParams.get('plan');
    if(plan === 'subscription') {
      await handlePaySubscriptions()

    }
    if(plan === 'credits') {
      await handlePayCredits()

    }
  }
  const handleRedirect= () => {
    navigate(`/${ROUTES.ASSETS}/${assetName}`)
  }
  return (
    <>
      <Grid sx={{ width: '100%' }}>
        <div className={styles.searchBar}>
          <SearchBar />
        </div>
        <Color
          src={constants.img_wide}
          crossOrigin='anonymous'
          format='hex'
        >
          {({ data }) => {
            if (data) {
              return (
                <>
                  {isLoading ? <SkeletonAssetsDetail data={data} /> : <div
                    style={{
                      background: `linear-gradient(to bottom right, ${
                        data + '40'
                      }, ${data + '10'})`
                    }}
                  >
                    <Container sx={{paddingTop: 4}}>
                      <Grid container spacing={2}>
                        <Grid  item xs={12} sm={12} md={7}>
                          <Button size="small" onClick={handleRedirect} sx={{fontSize: 10}}><ArrowBackIcon sx={{ height: 15, width: 15 }}/>{t('go_image_detail')}</Button>
                          <ImageListItem className={styles.imageWrapper} key={`${imageData?.originalFilename}-${imageData?.mediumURL}`}>
                            <img
                              src={imageData?.mediumURL}
                              alt={imageData?.originalFilename}
                              loading='lazy'
                              className={styles.image}
                            />
                          </ImageListItem>
                          {isLoading ? <Skeleton variant='rectangular' width={200} /> : <div className={styles.description}>
                            {imageData?.title}
                          </div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} sx={{paddingBottom: 5, marginTop: '20px'}}>

                         <CardCreditSubscription hideData={dataParams.get('plan') === 'fast-download'} imageId={assetId} planInformation={planInformation} imageInformation={imageInformation1} onDownloadImage={downloadImage}/>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>}
                </>
              )
            }
          }}
        </Color>
      </Grid>

    </>
  )
}
import * as moment from "moment"
import {
    KEYWORDS_EPS,
    KEYWORDS_EPS_PNG,
    KEYWORDS_IS_ADULT,
    KEYWORDS_IS_AI,
    KEYWORDS_IS_EDITORIAL,
    KEYWORDS_PNG
} from './constants'
export const daysBetweenDates = (date1 : moment.Moment, date2 : moment.Moment) => {
    return date1.diff(date2, 'days')
}
export const monthsBetweenDates = (date1 : moment.Moment, date2 : moment.Moment) => {
    return (date2.diff(date1, 'months', true))
}
export const formatDollar = (value: number) => {
    if(value === 0){
        return `$${value}`
    }
    if(value !== undefined) {
        return `$${value?.toFixed(2)}`
    }
    else return  '-'
}
export const constructSizes = (data: any) => {
    const  sizes = [] as any;
    (data?.sizes || []).forEach((size: any) => {
        const findLicenseId = sizes.find((s:any) => s.licenseId === size.licenseId);
        const findLicenseIdIndex = sizes.findIndex((s:any) => s.licenseId === size.licenseId);
        const licenseSize = [{
            type: 'jpg'
        },{
            type: 'png'
        }, {
            type: 'eps'
        }] as any;
        const license = licenseSize.map((lic: any) => {
            if(lic.type === 'jpg' && size.jpg){
                return {
                    ...lic,
                    sizes: lic.sizes?.length ? [...lic.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                }
            }
            if(lic.type === 'eps' && size.eps){
                return {
                    ...lic,
                    sizes: lic.sizes?.length ? [...lic.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                }
            }
            if(lic.type === 'png' && size.png){
                return {
                    ...lic,
                    sizes: lic.sizes?.length ? [...lic.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id, displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                }
            }
            return  {
                ...lic
            }
        })
        if(!findLicenseId) {
            sizes.push({licenseId: size.licenseId, licenseName: size.licenseName, licenses: [...license]})
        }
        else {
            const newLicenseId = {
                ...findLicenseId,
                licenses: findLicenseId.licenses.map((li: any) => {
                    if(li.type === 'jpg' && size.jpg){
                        return {
                            ...li,
                            sizes: li.sizes?.length ? [...li.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                        }
                    }
                    if(li.type === 'eps' && size.eps){
                        return {
                            ...li,
                            sizes: li.sizes?.length ? [...li.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id, displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                        }
                    }
                    if(li.type === 'png' && size.png){
                        return {
                            ...li,
                            sizes: li.sizes?.length ? [...li.sizes, {id:size.id,displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}] : [{id:size.id, displayPrice: size?.displayPrice, price: size?.price, name: size?.name, pixels: {...size?.pixels}, inches: {...size?.inches}}]
                        }
                    }
                    return  {
                        ...li
                    }
                })
            }
            sizes[findLicenseIdIndex] = {...newLicenseId}
        }
    })
    return sizes;
}

export const areObjectValuesEmpty = (obj: any) =>  {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            // Check if the value is not empty
            if (
              value !== null &&
              value !== undefined &&
              !(Array.isArray(value) && value.length === 0) &&
              !(typeof value === 'object' && Object.keys(value).length === 0) &&
              !(typeof value === 'string' && value.trim() === '')
            ) {
                return false; // If any value is not empty, return false
            }
        }
    }
    return true; // If all values are empty, return true
}

export const findDuplicateImage = (arr: any) => {
    return arr.filter(
      (obj: any , index: any ) =>
        arr.findIndex((item: any) => item.name === obj.name) !== index
    );
}

export const keywordsDefaultStep3 = (image: any) => {
    let defaultKeywords = [] as string[];

    if(image.hasEPS === 1 && image.hasPNG === 0) {
        defaultKeywords = [...KEYWORDS_EPS];
    } else if(image.hasEPS === 0 && image.hasPNG === 1) {
        defaultKeywords = [...KEYWORDS_PNG];
    } else if(image.hasEPS === 1 && image.hasPNG === 1) {
        defaultKeywords = [...KEYWORDS_EPS_PNG];
    }
    if (image.isAdult && !image.isEditorial) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_ADULT]; // replace with your actual adult keywords
    }
    if (image.isAdult && image.isEditorial && !image.isAi) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_ADULT, ...KEYWORDS_IS_EDITORIAL]; // replace with your actual adult keywords
    }
    if (image.isEditorial && !image.isAdult) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_EDITORIAL];
    }
    if (image.isAdult && image.isEditorial && image.isAi) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_ADULT, ...KEYWORDS_IS_EDITORIAL, ...KEYWORDS_IS_AI ,  `${image.networkAi}`]; // replace with your actual adult keywords
    }
    if (!image.isAdult && image.isEditorial && image.isAi) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_EDITORIAL, ...KEYWORDS_IS_AI ,  `${image.networkAi}`]; // replace with your actual adult keywords
    }
    if (!image.isAdult && !image.isEditorial && image.isAi) {
        defaultKeywords = [...defaultKeywords, ...KEYWORDS_IS_AI ,  `${image.networkAi}`]; // replace with your actual adult keywords
    }
    return defaultKeywords
}
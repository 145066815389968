import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import styles from './AddToLightboxDialog.module.scss'
import CustomAutocomplete from '../../../../../../shared-components/customAutocomplete'
import instance from '../../../../../../../providers/axios.config'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import { BUYER } from '../../../../../../../utils/constants'
import { ISizeSelected } from '../../../../../../../utils/types'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key?: string, title?: string, description?: string) => void
  dialogTitle: string
  sizeSelected?: ISizeSelected;
}

type lightboxType = {
  id: number,
  name: string,
  description: string
}

export const AddToLightboxDialog = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle, sizeSelected } = props
  const { t } = useTranslation()
  const userRole = useSelector((state: RootState) => state.user.userRole)

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [selectedLightbox, setSelectedLightbox] = useState({id: '', name: '', description: ''})
  const [newLightbox, setNewLightbox] = useState(false)
  const [lightBoxes, setLightBoxes] = useState<lightboxType[]>([])

  useEffect(() => {
    if(userRole?.slug === BUYER){
      getLightBoxList()
    }
  }, [userRole])
  const getLightBoxList = async () => {
    setIsLoading(true)
    try {
      const responseLightBox = await instance.get(`favorites/lists`)
      setLightBoxes(responseLightBox?.data?.list || [])
      setIsLoading(false)
    } catch (error) {
      console.error('Fetch error:', error)
    }
  }
  const handleAddLightBox = () => {
    setSelectedLightbox({id: '', name: '', description: ''})
    setNewLightbox(false)
    setDescription('')
    setTitle('')
    handleConfirm(selectedLightbox?.id, title, description)
  }
  const selectLightBox = (lightBox: any) => {
    setSelectedLightbox(lightBox)
  }
  const setDisabled = () => {
    if(newLightbox) {
      return !(title && description);
    } else {
      return !selectedLightbox?.name
    }
  }
  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <Alert severity="info">{t('size_and_format_selected')}</Alert>
          <Box display={'flex'} flexDirection={'row'} gap={5} alignItems={'flex-start'}>
            <Typography variant={'body1'}>
              {t('license')}
              <Typography variant='subtitle2' color={'red'}>{sizeSelected?.licenseName}</Typography>
            </Typography>
            <Typography>
              {t('Size')}
              <Box display={'flex'} flexDirection={'row'} gap={1}>
                <Typography variant='subtitle2' color={'red'}>
                  {sizeSelected?.sizeName}
                </Typography>
                {sizeSelected?.sizePixels?.height && sizeSelected?.sizePixels?.width ? (
                  <Typography variant='subtitle2' color='red'>{`${parseInt(`${sizeSelected?.sizePixels?.width}`)}x${parseInt(`${sizeSelected?.sizePixels?.height}`)}px`}</Typography>
                ) : null}
              </Box>
            </Typography>   
            <Typography variant={'body1'}>
              {t('Format')}
              <Typography variant='subtitle2' color={'red'}>{sizeSelected?.type.toUpperCase()}</Typography>
            </Typography>        
          </Box>
          <CustomAutocomplete loading={isLoading} value={selectedLightbox?.name} onSetValue={selectLightBox} options={lightBoxes}  label={t('License')}/>

          <FormControlLabel
            control={
              <CustomCheckbox
                checked={newLightbox}
                onChange={(e: any) => setNewLightbox(e.target.checked)}
              />
            }
            label={t('New Lightbox')}
          />

          {newLightbox && (
            <>
              <CustomTextfield
                size='small'
                name='title'
                variant='outlined'
                label={t('Title')}
                value={title}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setTitle(e.target.value)}
              />

              <CustomTextfield
                size='small'
                name='title'
                variant='outlined'
                label={t('Description')}
                value={description}
                onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDescription(e.target.value)}
              />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} variant='outlined'>
          {t('Cancel')}
        </Button>
        <Button onClick={handleAddLightBox} disabled={setDisabled()}
                variant='contained' color='primary'>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../../../../utils/constants'
import { Title } from '../../Title'
import { ReleaseForm } from './ReleaseForm'

const releasesForms = [
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Property Release forms'),
    pdfReleaseFormType: i18next.t('PDF Property Release form'),
    digitalReleaseFormType: i18next.t('Digital Property Release form'),
    pathPdf: 'forms/Property Release LAYERSTOCK.pdf',
    pathDigital: 'forms/Property Release LAYERSTOCK.pdf',
    name: 'Property Release LAYERSTOCK.com'
  },
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Model Release forms'),
    pdfReleaseFormType: i18next.t('PDF Model Release form'),
    digitalReleaseFormType: i18next.t('Digital Model Release form'),
    pathPdf: 'forms/Model Release LAYERSTOCK.pdf',
    pathDigital: 'forms/Model Release LAYERSTOCK.pdf',
    name: 'Model Release LAYERSTOCK.com'
  },
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Minor Release forms'),
    pdfReleaseFormType: i18next.t('PDF Minor Release form'),
    digitalReleaseFormType: i18next.t('Digital Minor Release form'),
    pathPdf: 'forms/Minor Release LAYERSTOCK.pdf',
    pathDigital: 'forms/Minor Release LAYERSTOCK.pdf',
    name: 'Minor Release LAYERSTOCK.com'
  },
]

export const ReleaseFormsList = () => {
  const { t } = useTranslation()
  console.log('releasesForms', releasesForms);
  return (
    <div>
      <Title title={t('Releases Forms')} />
      <ReleaseForm releases={releasesForms} />
    </div>
  )
}

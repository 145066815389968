import { Controller, useForm } from 'react-hook-form'
import { CustomTextfield } from '../textfield/CustomTextField'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles
  from '../../main-components/content/account-details/sections/seller/upload/releases-types/ReleasesTypes.module.scss'
import React from 'react'
import { Alert, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import { SELLER } from '../../../utils/constants';

export interface IFormInput {
  name: string;
  email: string;
  message: string;
}
const schema = yup.object().shape({
  name: yup.string().required('name_required'),
  email: yup.string().email('email_not_valid').required('email_required'),
  message: yup.string().required('message_required'),
});
type IFormTabProps = {
  tab: number
  onSubmitForm: (data: IFormInput) => void
}
export const FormTab = (props: IFormTabProps) => {
  const {tab, onSubmitForm} = props;
  const { t } = useTranslation()
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const user = useSelector((state: RootState) => state.user);

  const imLoggedIn = () => !!user.usersData;
  const imSeller = () => imLoggedIn() && user.userRole && user.userRole.slug === SELLER;

  const onSubmit = async (data: IFormInput) => {
    const response  = await onSubmitForm(data);
    if ((response as any)?.status === 'success') {
      reset({
        name: '',
        email: '',
        message: ''
      });
    }
  };

  const renderContent = () => {
    if (tab === 1 && (!imLoggedIn() || !imSeller())) {
      return <Alert severity="info">{t('report_violation_as_seller')}</Alert>
    }
    if (!tab && !imLoggedIn()) {
      return <Alert severity="info">{t('tell_a_friend_alert')}</Alert>
    }
    return renderCommonForm();
  };
  const renderCommonForm = () => {
    return (
      <>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...newField } }) =>
            <CustomTextfield className={styles.textfield}
              size='small'
              inputRef={ref}
              error={errors?.name}
              helperText={errors?.name?.message ? t(errors?.name?.message) : ''}
              variant='outlined'
              label={`${!tab ? t('Friend Name') : t('Full Name')}`}
              {...newField} 
            />
          }
        />
        <Controller
          name="email"
          control={control}
          defaultValue={tab === 1 && imSeller() ? user.usersData?.email : ''}
          disabled={tab === 1 && !!imSeller()}
          render={({ field: { ref, ...newField } }) =>
            <CustomTextfield className={styles.textfield}
              size='small'
              inputRef={ref}
              variant='outlined'
              error={errors?.email}
              helperText={errors?.email?.message ? t(errors?.email?.message) : ''}
              label={!tab ? t('Friend Email') : t('Email')}
              {...newField} 
            />
          }
        />
        <Controller
          name="message"
          control={control}
          render={({ field: { ref, ...newField } }) =>
            <CustomTextfield className={styles.textfield}
              size='small'
              multiline
              rows={4}
              inputRef={ref}
              variant='outlined'
              error={errors?.message}
              helperText={errors?.message?.message ? t(errors?.message?.message) : ''}
              label={t('message')}
              {...newField} 
            />
          }
        />
        {tab === 1 ? <Typography sx={{ fontSize: 14, paddingBottom: 1, paddingTop: 1 }} color="text.secondary" gutterBottom>
          {t('report_footer')}
        </Typography>: null}
        <Button
          type="submit"
          disabled={Object.keys(errors).length > 0}
          variant="contained"
        >
          {t('Send')}
        </Button>
    </>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {tab === 1 ? <Typography sx={{ fontSize: 14, paddingBottom: 1, paddingTop: 1 }} color="text.secondary" gutterBottom>
        {t('report_title')}
      </Typography> : null}
      {renderContent()}  
  </form>
  )
}
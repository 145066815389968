import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box, Button,
  FormControl,
  FormControlLabel, IconButton,
  RadioGroup,
  Typography
} from '@mui/material'
import styles from '../../main-components/content/asset-details/AssetDetails.module.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomRadio } from '../checkbox/CustomRadio'
import { useTranslation } from 'react-i18next'
import {
  IExtensionImage,
  IInches,
  ILicenseSize,
  IPixels,
  ISize,
  ISizeLicense,
  ISizeSelected
} from '../../../utils/types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../utils/constants'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

type Props = {
  showOptionsUser?: boolean,
  isFavorite?: boolean,
  isAnonymousUser?: boolean,
  sizeSelected?: ISizeSelected,
  handleChangeSize: (item: {
    licenseId: number,
    licenseName: string,
    sizeId: number,
    displaySizePrice: string,
    sizePrice: number,
    sizeName: string,
    sizePixels: {
      width: number;
      height: number;
    },
    sizeInches: {
      width: number;
      height: number;
    },
    extension: string
  }) => void,
  handleChangeInnerAccordion: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void,
  expandedInner?: string | null,
  handlePayCredits: () => void,
  handlePaySubscriptions: () => void,
  handleSelectPaymentOption: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeMainAccordion: (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => void,
  expandedMain?: number | null,
  item?: IExtensionImage,
  onOpenLightBox?: (key?: any, key1?: any) => void,
}
const displayInchPixels = (inches?: IInches, pixels?: IPixels, isShowPixels?: boolean) => {
  if(isShowPixels) {
    return pixels?.width === 0 ? '' : `${Math.floor(pixels?.width || 0)} x ${Math.floor(pixels?.height || 0)} px`;
  } else {
    return inches?.dpi === 0 ? '' :`${inches?.width?.toFixed(2)}" x ${inches?.height?.toFixed(2)}" @${inches?.dpi} dpi`
  }
}

export const AccordionAssetsDetail = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showPixesls, setShowPixels] = useState(true)
  const {item, expandedMain,isAnonymousUser,onOpenLightBox, isFavorite, handleChangeMainAccordion, handleSelectPaymentOption,handlePaySubscriptions,handlePayCredits, expandedInner, handleChangeInnerAccordion, handleChangeSize, sizeSelected, showOptionsUser} = props;

  const handleChangePixels = () => {
    setShowPixels(!showPixesls)
  }

  const handleRedirectLogin = () => {
    navigate(`/${ROUTES.REGISTER}`)
  }
  const handleOpenLightBox = (size: any, type: any) => {
    if(onOpenLightBox) {
      onOpenLightBox(size, type)
    }
  }

  return (
    <Accordion
      key={item?.licenseId}
      expanded={expandedMain === item?.licenseId}
      onChange={handleChangeMainAccordion(item?.licenseId || 0)}
      className={styles.accordion}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={styles.accordionSummarys}
      >
        <Typography sx={{ color: 'text.secondary' }}>
          {item?.licenseName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ minWidth: 300 }}>
        <FormControl component='fieldset'>
          <RadioGroup
            defaultValue='0'
            name='orientation'
            onChange={handleSelectPaymentOption}
          >
            {(item?.licenses || []).map(
              (photoExtension: ILicenseSize) => {
                return (
                <>{photoExtension.sizes?.length && <Accordion
                  sx={{ marginBottom: 1 }}
                  key={`${photoExtension.type}-extension-${item?.licenseId}`}
                  expanded={
                    expandedInner === photoExtension.type
                  }
                  onChange={handleChangeInnerAccordion(
                    photoExtension.type
                  )}
                  className={styles.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={styles.accordionSummarys}
                  >
                    <Box>
                      {photoExtension.type.toUpperCase()}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ minWidth: 300 }}
                  >
                    <Button size="small" sx={{marginLeft: 1, textTransform: 'none'}} onClick={handleChangePixels}>{showPixesls ? t('show_inches') : t('show_pixels')}</Button>
                    {(photoExtension.sizes).map(
                      (itemSize: ISizeLicense) => {
                        return (
                        <div key={`sizes-${photoExtension.type}-${itemSize.id}-${item?.licenseId}`}>
                          <FormControlLabel
                            // onChange={() => handleChangeSize(itemSize.id,item?.licenseId, itemSize?.displayPrice, itemSize.price, photoExtension.type, item?.licenseName)}
                            onChange={() => handleChangeSize({
                              // itemSize.id,item?.licenseId, itemSize?.displayPrice, itemSize.price, photoExtension.type, item?.licenseName
                              licenseId: item?.licenseId || 0,
                              licenseName: item?.licenseName || '',
                              sizeId: itemSize.id,
                              displaySizePrice: itemSize.displayPrice,
                              sizePrice: itemSize.price,
                              sizeName: itemSize.name || '',
                              sizePixels: itemSize.pixels || { width: 0, height: 0 },
                              sizeInches: itemSize.inches || { width: 0, height: 0, dpi: 300 },
                              extension: photoExtension.type,
                            })}
                            value={itemSize.id}
                            checked={itemSize.id === sizeSelected?.id}
                            control={<CustomRadio />}
                            label={`${itemSize.name} ${displayInchPixels(itemSize?.inches, itemSize.pixels, showPixesls)}`}
                            className={styles.radiobox}
                          />
                            {showOptionsUser && itemSize.price > 0 && (
                              <IconButton
                                disabled={sizeSelected?.id !== itemSize.id}
                                onClick={() => handleOpenLightBox(itemSize.id, photoExtension.type)}
                              >
                                <FavoriteBorderIcon sx={{ height: 20, width: 20 }} />
                              </IconButton>
                            )}
                        </div>
                      )}
                    )}
                  </AccordionDetails>
                </Accordion>}
                </>
              )}
            )}
          </RadioGroup>
        </FormControl>

        {isAnonymousUser && sizeSelected?.price !== 0 && <div className={styles.buttonsContainer}>
          <Box>
            <Button
              variant='contained'
              className={styles.redButton}
              sx={{
                '&:hover': {
                  backgroundColor: '#c52d2f',
                },
              }}
              onClick={handleRedirectLogin}
            >
              {t('Sign up')}
            </Button>
          </Box>
        </div>}
        {showOptionsUser && <div className={styles.buttonsContainer}>
          <Box>
            <Typography sx={{ fontSize: 10 }} color='text.secondary' gutterBottom>
              {t('download_with_credits')}
            </Typography>
            <Button
              variant='contained'
              className={styles.redButton}
              sx={{
                '&:hover': {
                  backgroundColor: '#c52d2f',
                },
              }}
              onClick={handlePayCredits}
            >
              {sizeSelected?.price} {t('Credits')}
            </Button>
          </Box>
          <Box>
            <Typography sx={{ fontSize: 10 }} color='text.secondary' gutterBottom>
              {t('download_with_subscription')}
            </Typography>
            <Button
              variant='contained'
              onClick={handlePaySubscriptions}
              className={styles.redButton}
              sx={{
                '&:hover': {
                  backgroundColor: '#c52d2f',
                },
              }}
            >
              {sizeSelected?.price === 0 ?  t('free_download') : t('Download')}
            </Button>
          </Box>
        </div>}
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionAssetsDetail;